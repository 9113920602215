import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enviarConfirmacionCorreo, enviarInvitacionCorreo, obtenerPlantillaCorreo } from '../../actions/controlCapacitacionesActions';
import SpinnerLoading from '../../view/components/Spinners/SpinnerLoading';
import Swal from 'sweetalert2';

const EmailPreview = ({ datos, tipo }) => {

  const dispatch = useDispatch();
  const [htmlTemplate, setHtmlTemplate] = useState(""); // Plantilla base
  const { usuario } = useSelector(state => state.usuario);

  const [placeholders, setPlaceholders] = useState({
    nombre_usuario: tipo == 2 && datos.docente_confirmado[0] ? datos.docente_confirmado[0].nombre_usuario + ' ' + datos.docente_confirmado[0].apellido_usuario : '"Nombre del docente"',
    nombre_proyecto: datos.nombre_proyecto,
    publico_objetivo: datos.publico_objetivo ? datos.publico_objetivo : '',
    nombre_sesion: datos.nombre_sesion,
    nombre_emisor: usuario.nombre_usuario + " " + usuario.apellido_usuario,
    nombre_modulo: datos.nombre_modulo,
    link_drive: datos.link_drive,
    ruta_archivo: datos.ruta_archivo,
  }); // Datos dinámicos
  const [datosCorreo, setDatosCorreo] = useState(datos);

  const [previewHtml, setPreviewHtml] = useState(""); // HTML para previsualizar

  //Se formatea la fecha para el preview del correo
  const formateoFecha = (fechaSesion, horaIngreso, horaSalida) => {
    // Paso 1: Crear objetos Date para la fecha y las horas de ingreso y salida
    const fecha = new Date(fechaSesion);
    const [horaIngresoHoras, horaIngresoMinutos, horaIngresoSegundos] = horaIngreso.split(':').map(Number);
    const [horaSalidaHoras, horaSalidaMinutos, horaSalidaSegundos] = horaSalida.split(':').map(Number);

    const horaIngresoDate = new Date(fecha);
    horaIngresoDate.setHours(horaIngresoHoras, horaIngresoMinutos, horaIngresoSegundos);

    const horaSalidaDate = new Date(fecha);
    horaSalidaDate.setHours(horaSalidaHoras, horaSalidaMinutos, horaSalidaSegundos);

    // Paso 2: Formatear la fecha al estilo "Jueves 24 de octubre de 2024"
    const diasSemana = [
      'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'
    ];
    const meses = [
      'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
      'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];

    const diaSemana = diasSemana[fecha.getDay()];
    const diaMes = fecha.getDate();
    const mes = meses[fecha.getMonth()];
    const anio = fecha.getFullYear();

    const fechaFormateada = `${diaSemana} ${diaMes} de ${mes} de ${anio}`;

    // Paso 3: Formatear las horas de ingreso y salida
    const opcionesHora = { hour: 'numeric', minute: 'numeric', hour12: true };
    const horaIngresoFormateada = horaIngresoDate.toLocaleTimeString('es-ES', opcionesHora);
    const horaSalidaFormateada = horaSalidaDate.toLocaleTimeString('es-ES', opcionesHora);

    // Paso 4: Construir la cadena final
    const cadenaFinal = `${fechaFormateada}, de ${horaIngresoFormateada} a ${horaSalidaFormateada}`;

    // Retornar el resultado
    return cadenaFinal;
  }

  // Para copiar el mensaje
  const handleCopy = () => {
    const copymensaje = formatMensaje();
    navigator.clipboard.writeText(copymensaje)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Mensaje copiado al portapapeles',
          showConfirmButton: false,
          timer: 1500
        });
      })
      .catch(err => {
        console.error('Error al copiar el mensaje:', err);
      });
  };

  const formatMensaje = () => {
    let mensaje = '';
    if (tipo == 1) {//Es de invitacion

      mensaje=`*Estimado Profesor ${placeholders.nombre_usuario},*\n
Es un gusto comunicarnos con Usted, es así que queremos hacerle extensivo el amable saludo a nombre de todo el equipo de *INEDI Posgrados®*, esperamos que se encuentre muy bien.\n
Estimado maestro, nos encontramos próximos a iniciar nuestro *${placeholders.nombre_proyecto}*; y sabiendo de su loable labor en el dictado de la materia, le hacemos extensiva invitación a formar parte de nuestra plana docente.\n
*A continuación, tenemos la siguiente propuesta para usted:*\n
- *Sesión:* "Beneficios Sociales"
- *Fecha de clase:* ${placeholders.horario}
- *Plataforma:* Zoom\n
*Finalmente, cualquier duda o consulta puede realizarla a:*\n
- *Dirección de correo electrónico:* academica@inedi.edu.pe
- *Contacto:*\n
  - *Cel.:* 944-674-050 - Sthefani Garayar Zevallos
  - *Cel.:* 983-868-176 - Equipo de Experiencia\n
De manera muy gustosa lo atendemos.\n
Esperando su respuesta, me despido de Usted, reiterándole muestras de mi especial cariño y consideración.`;


    }else if (tipo == 2) {//Es de confirmacion

      mensaje=`*Estimado Prof. ${placeholders.nombre_usuario},*\n
  Gracias por su confirmación. Asimismo, detallamos la información de la clase y procederemos a remitirle los enlaces (links) tanto de la clase en vivo, como el de la carpeta virtual donde encontrará los trabajos de los discentes.\n
  *A continuación detallamos lo mencionado:*\n
    - *Sesión:* "${placeholders.nombre_sesion}"
    - *Fecha:* ${placeholders.horario}
    - *Modalidad:* Virtual
    
  *Clase en vivo:* 
  ${placeholders.ruta_archivo}\n
  *Carpeta de Trabajos:* 
  ${placeholders.link_drive}\n
  Si tiene alguna consulta, no dude en comunicarse con nosotros.`;
    }

    return mensaje;
  };



  //preparamos los datos del preview del correo
  useEffect(() => {
    dispatch(obtenerPlantillaCorreo(tipo)).then((response) => {
      setHtmlTemplate(response.plantilla);
    });

    // Formatear la fecha de la sesión
    let fechaSesion = '';
    if (datos.hora_ingreso_tur_m && datos.hora_salida_tur_m) {
      fechaSesion = formateoFecha(datos.fecha_sesion, datos.hora_ingreso_tur_m, datos.hora_salida_tur_m);
    } else if (datos.hora_ingreso_tur_t && datos.hora_salida_tur_t) {
      fechaSesion = formateoFecha(datos.fecha_sesion, datos.hora_ingreso_tur_t, datos.hora_salida_tur_t);
    }

    let listaObjetivos = '';
    // Iterar sobre la lista de objetivos y concatenar cada ítem en la listaObjetivos
    datos.objetivos.forEach((objetivo) => {
      listaObjetivos += `<li><strong>${objetivo.titulo_objetivo}: </strong>${objetivo.descripcion}</li>`;
    });
    setPlaceholders({
      ...placeholders,
      lista_objetivos: listaObjetivos,
      horario: fechaSesion,
    });

  }, []);

  //añadimos el usuario que esta enviando el correo
  useEffect(() => {
    setDatosCorreo({
      ...datosCorreo,
      usuario: usuario,
    })
  }, [usuario]);


  //cargamos los datos en el preview del correo
  useEffect(() => {
    if (htmlTemplate) {
      let filledTemplate = htmlTemplate;
      Object.keys(placeholders).forEach((key) => {
        const regex = new RegExp(`{{${key}}}`, "g");
        filledTemplate = filledTemplate.replace(regex, placeholders[key]);
      });
      setPreviewHtml(filledTemplate);
    }
  }, [htmlTemplate, placeholders]);

  //enviamos el correo
  const handleEnviarCorreo = () => {
    if (tipo == 1) {
      dispatch(enviarInvitacionCorreo(datosCorreo));
    } else {
      dispatch(enviarConfirmacionCorreo(datosCorreo));
    }
  };

  return (
    <>
      {!previewHtml ? <SpinnerLoading /> :
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: previewHtml }}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
            }}
          ></div>
          <button className="btn btn-primary mt-2" onClick={() => handleEnviarCorreo()}>Enviar correo</button>
          <button className="btn btn-success mt-2 ml-3" onClick={() => handleCopy()}>Copiar mensaje Whatsapp</button>
        </div>
      }
    </>
  );
};

export default EmailPreview;