import { color } from 'highcharts';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2/src/sweetalert2';

const FormNuevoObjetivo = ({cerrarModal,setDatosProyecto,objetivos,objetivoEdit}) => {
    const [nuevoObjetivo, setNuevoObjetivo] = useState({ titulo_objetivo: '', descripcion: '' });
    const {titulo_objetivo,descripcion,index} = nuevoObjetivo;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNuevoObjetivo({
            ...nuevoObjetivo,
            [name]: value
        });
    };

    //Aqui se añade un nuevo objetivo
    const handleAddObjetivo = () => {      
        if(objetivoEdit){
            const objetivosActualizados = [...objetivos];
            objetivosActualizados[index] = nuevoObjetivo;
            setDatosProyecto((prevState) => ({
                ...prevState,
                objetivos: objetivosActualizados,
            }));
            setNuevoObjetivo({ titulo_objetivo: '', descripcion: '' });
            cerrarModal();
        }else if (titulo_objetivo.trim() !== '' && descripcion.trim() !== '') {
            const objetivosActualizados = [...objetivos,nuevoObjetivo];
            setDatosProyecto((prevState) => ({
                ...prevState,
                objetivos: objetivosActualizados,
            }));
            setNuevoObjetivo({ titulo_objetivo: '', descripcion: '' });
            cerrarModal();
        }else{
            Swal.fire({
                title: 'Error',
                text: 'Todos los campos son requeridos',
                icon: 'error',
            });
        }
    };

    useEffect(() => {
        if(objetivoEdit){
            setNuevoObjetivo(objetivoEdit);
        }
    }, []);

    return (
        <div >
            <Form.Group>
            <Form.Row>
                <Form.Label style={{color:'white'}}>Titulo</Form.Label>
                <Form.Control
                    type='text'
                    placeholder='Agregar titulo del objetivo'
                    name='titulo_objetivo'
                    value={titulo_objetivo}
                    onChange={handleChange}
                />
                <Form.Label className='mt-3' style={{color:'white'}}>Descripcion</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder='Agregar descripcion del objetivo'
                    name='descripcion'
                    value={descripcion}
                    onChange={handleChange}
                    rows={4} 
                />
                <Button className='mt-3' variant='info' onClick={handleAddObjetivo}>{objetivoEdit ? 'Guardar cambios':'Agregar Objetivo'}</Button>
            </Form.Row>

        </Form.Group>
        </div>
        
    );
};

export default FormNuevoObjetivo;