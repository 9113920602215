import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { REQUIRED, TYPENUMBER, TYPE_NUMBER_INT, TYPE_NUMBER_POSITIVE } from '../../../../helpers/validationForms';
import { useParams } from 'react-router-dom';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { editarNivelAcademicoProyecto } from '../../../../actions/cursosActions';

const FormNivelAcademicoProyecto = ({ academico }) => {
  console.log(academico)
  const { loadingEdit } = useSelector(state => state.cursos);
  const { idProyecto } = useParams();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: academico || initialValues(),
    validationSchema: yup.object(validationSchema()),
    onSubmit: valores => {
      dispatch(editarNivelAcademicoProyecto(idProyecto, valores));
    }
  }); 

  const mostrarCarga = () => { 
    if(loadingEdit) {
      return <Spinner animation='border' size='sm'/>
    } else { 
      return <FontAwesomeIcon icon={faSave} />
    }
  }

  return ( 
    <div className="FormNivelAcademicoProyecto">
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label>Nombre del Proyecto</Form.Label>
            <Form.Control 
              type='text'
              name='nombre_proyecto'
              value={formik.values.nombre_proyecto}
              onChange={formik.handleChange}
              isInvalid={formik.errors.nombre_proyecto}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.nombre_proyecto}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Duración</Form.Label>
            <Form.Row>
              <Col md={8} className='px-0 pr-2'>
                <Form.Control
                  type='text'
                  name='cantidad_lapso'
                  value={formik.values.cantidad_lapso}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.cantidad_lapso}
                />
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.cantidad_lapso}
                </Form.Control.Feedback>
              </Col>
              <Col md={4} className='px-0'>
                <Form.Control
                  as='select'
                  name='id_tp_lapso'
                  value={formik.values.id_tp_lapso}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.id_tp_lapso}
                >
                  <option value="" disabled>--Seleccione--</option>
                  <option value="1">Días</option>
                  <option value="2">Meses</option>
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {formik.errors.id_tp_lapso}
                </Form.Control.Feedback>
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group as={Col} md={4}>
            <Form.Label>Lugar</Form.Label>
            <Form.Control
              type='text'
              name='sede'
              value={formik.values.sede}
              onChange={formik.handleChange}
              isInvalid={formik.errors.sede}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.sede}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>Certificado</Form.Label>
            <Form.Control
              type='text'
              name='certificado'
              value={formik.values.certificado}
              onChange={formik.handleChange}
              isInvalid={formik.errors.certificado}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.certificado}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Especializacion</Form.Label>
            <Form.Control
              type='text'
              name='especializacion'
              value={formik.values.especializacion}
              onChange={formik.handleChange}
              isInvalid={formik.errors.especializacion}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.certificado}
            </Form.Control.Feedback>
          </Form.Group>
          
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} md={6}>
            <Form.Label>Nota aprobatoria</Form.Label>
            <Form.Control
              type='text'
              name='nota_minima'
              value={formik.values.nota_minima}
              onChange={formik.handleChange}
              isInvalid={formik.errors.nota_minima}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.nota_minima}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Horas lectivas</Form.Label>
            <Form.Control
              type='text'
              name='hrs_lectivas'
              value={formik.values.hrs_lectivas}
              onChange={formik.handleChange}
              isInvalid={formik.errors.hrs_lectivas}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.hrs_lectivas}
            </Form.Control.Feedback>
          </Form.Group>
          
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} md={6}>
            <Form.Label>Horas académicas</Form.Label>
            <Form.Control
              type='text'
              name='hrs_academicas'
              value={formik.values.hrs_academicas}
              onChange={formik.handleChange}
              isInvalid={formik.errors.hrs_academicas}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.hrs_academicas}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Porcentaje de descuento (%)</Form.Label>
            <Form.Control
              type='text'
              name='porcentaje_descuento'
              value={formik.values.porcentaje_descuento}
              onChange={formik.handleChange}
              isInvalid={formik.errors.porcentaje_descuento}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.porcentaje_descuento}
            </Form.Control.Feedback>
          </Form.Group>
         
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} md={6}>
            <Form.Label>Porcentaje de penalidad (%)</Form.Label>
            <Form.Control
              type='text'
              name='porcentaje_penalidad'
              value={formik.values.porcentaje_penalidad}
              onChange={formik.handleChange}
              isInvalid={formik.errors.porcentaje_penalidad}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.porcentaje_penalidad}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label>Créditos</Form.Label>
            <Form.Control
              type='text'
              name='creditos'
              value={formik.values.creditos}
              onChange={formik.handleChange}
              isInvalid={formik.errors.creditos}
            />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.creditos}
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>
        <hr/>
        <Form.Row>
          <Form.Group as={Col} md={12}>
            <Button variant='info' disabled={loadingEdit} className='btn-icon btn-spinner' type='submit'>
              {mostrarCarga()}
              Guardar configuración académica
            </Button>
          </Form.Group>
        </Form.Row>
      </Form>
    </div>
  );
}
 
export default FormNivelAcademicoProyecto;

const initialValues = () => ({
  "nombre_proyecto" :"", 
  "cantidad_lapso":"", 
  "id_tp_lapso" :"", 
  "sede":"",
  "certificado" :"", 
  "nota_minima":"",
  "hrs_lectivas" :"", 
  "hrs_academicas" :"", 
  "porcentaje_descuento":"",
  "porcentaje_penalidad" :"",
  "creditos": "",
  "especializacion": ""
})

const validationSchema = () => (
  {
    "nombre_proyecto" : yup.string().required(REQUIRED), 
    "cantidad_lapso": yup.number()
      .typeError(TYPENUMBER)
      .integer(TYPE_NUMBER_INT)
      .required(REQUIRED), 
    "id_tp_lapso" : yup.string().required(REQUIRED).nullable(), 
    "sede": yup.string().required(REQUIRED).nullable(),
    "certificado" : yup.string().required(REQUIRED).nullable(), 
    "nota_minima": yup.number()
      .min(0, 'Poner una nota entre el rango de 0 - 100')
      .max(100, 'Poner una nota entre el rango de 0 - 100')
      .typeError(TYPENUMBER)
      .integer(TYPE_NUMBER_INT)
      .required(REQUIRED),
    "hrs_lectivas" : yup.number()
      .typeError(TYPENUMBER)
      .integer(TYPE_NUMBER_INT)
      .required(REQUIRED),
    "hrs_academicas" : yup.number()
      .typeError(TYPENUMBER)
      .integer(TYPE_NUMBER_INT)
      .required(REQUIRED),
    "porcentaje_descuento": yup.number()
      .min(0, 'Poner una nota entre el rango de 0 - 100')
      .max(100, 'Poner una nota entre el rango de 0 - 100')
      .typeError(TYPENUMBER)
      .required(REQUIRED),
    "porcentaje_penalidad" : yup.number()
      .min(0, 'Poner una nota entre el rango de 0 - 100')
      .max(100, 'Poner una nota entre el rango de 0 - 100')
      .typeError(TYPENUMBER)
      .required(REQUIRED),
    "creditos": yup.number()
      .typeError(TYPENUMBER)
      .required(REQUIRED),
  }
)
