import React, { useEffect, useState } from "react";
import HeaderContent from "../General/HeaderContent";
import Breadcrumb from "../General/Breadcrumb";
import Layout from "../../../app/Layout";
import { Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import widthModal from "../../../hoc/widthModal";
import ModalLarge from "../../components/Modals/ModalLarge/ModalLarge";
import Agenda from "../../components/Agenda";
import MaterialTableDemo from "../../components/plugins/MaterialTableDemo";
import MensajeLoading from "../../components/MensajeLoading";
import SpinnerLoading from "../../components/Spinners/SpinnerLoading";
import useSelectProyecto from "../../../hooks/useMultiSelectProyectos";
import "./ReporteDesertados.scss";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { obternerReporteDesertados, guardarProyectoSelect } from "../../../actions/reportesActions";
import { guardarApuntes, eliminarApunte } from '../../../actions/apuntesActions'
import { compose } from "redux";

const ReporteDesertados = (props) => {

  const { updateModalLarge, show } = props;
  const dispatch = useDispatch();

  const { cantidadesTotales, reporteDesertados, proyectoSelect } = useSelector((state) => state.reporte.desertados);
  const { loading } = useSelector((state) => state.reporte);
  const guardarObservaciones = (id) => dispatch(guardarApuntes(id))
  const listarReporteDesertados = (idProyecto) => dispatch(obternerReporteDesertados(idProyecto));

  const [desertado, setDesertado] = useState({})
  const [proyecto, SelectProyectos] = useSelectProyecto(proyectoSelect)
  const { usuario } = useSelector(state => state.usuario);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [showModal, setShowModal] = useState(false);


  //Funcion para hacer el filtro de acuerdo al click
  const filtroDesetadoSeleccionado = id => {
    reporteDesertados.map((desertados) => {
      let desertadofiter = desertados.reporte_desertado.filter(d => d.id_rptec_desertado === id)
      if (desertadofiter.length) {
        setDesertado(desertadofiter[0])
        guardarObservaciones(id)
      }
    })

  }

  //Funciopn que retorna boton para la columna
  const btnExaminar = ({ id_rptec_desertado }) => {

    return <Button onClick={() => {
      updateModalLarge()
      filtroDesetadoSeleccionado(id_rptec_desertado)
    }}
      className='btn-success'
    >
      <FontAwesomeIcon icon={faEye} />
    </Button>
  }

  const selectEstado = () => {
    return
  }

  //Funcion para guardar el select proyecto
  const guardarSeleccion = datos => {
    dispatch(guardarProyectoSelect(datos))
  }

  useEffect(() => {

    if (proyecto) {
      listarReporteDesertados(proyecto);
      // guardarSeleccion(proyecto)


    }


  }, [proyecto]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const toggleColumn = (columnName) => {
    const updatedVisibility = {
      ...columnVisibility,
      [columnName]: !columnVisibility[columnName]
    };
    setColumnVisibility(updatedVisibility);
    //localStorage.setItem('columnVisibility', JSON.stringify(updatedVisibility));
  };

  // Función para filtrar las columnas según la visibilidad
  const visibleColumns = colums(btnExaminar).filter(
    column => !columnVisibility[column.key]
  );

  return (
    <Layout>
      <HeaderContent title="SOS" icon='insert_chart_outlined' subItem={usuario.id_tp_nivel_usuario === '5' ? 'Tu misión: que tu cliente le saque el jugo a su tiempo e inversión.' : null} />
      <Breadcrumb
        link={[
          { nombre: "Inicio", url: "/inicio", icon: "fas fa-home" },
          { url: "", nombre: "Área de Trabajo" },
          { url: "", nombre: "SOS" },
        ]}
      />

      <div className="reporte-desertados">
        <div className="row content-select-curso mt-4">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6 filtro">
                <label htmlFor="">Producto</label>
                <SelectProyectos />
              </div>
            </div>
          </div>
          
          {reporteDesertados.length > 0 && (
            <div className="col-md-4 d-flex flex-column justify-content-start align-items-end mt-5">
              <button className="btn btn-primary" onClick={handleShowModal}>Columnas</button>
            </div>
          )}
        </div>

        {
          !proyecto ? (
            <MensajeLoading />
          ) : (
            <SpinnerLoading loading={loading}>
              {
                reporteDesertados.length ? reporteDesertados.map((proyecto, i) => (
                  <div className="p-0 mt-4 bg-white" key={i}>
                    <div className="continer-fluid border-bottom-0 border">
                      <h5
                        className="p-3 d-flex align-items-center mb-0"
                        style={{ color: "#434343" }}
                      >
                        <i className="fas fa-list pr-2"></i>Desertados de - {proyecto.nombre_proyecto}
                      </h5>
                    </div>
                    <div className="tabla border">

                      <MaterialTableDemo
                        colums={visibleColumns}
                        tabla={proyecto.reporte_desertado}
                        className='table-responsive-lg'
                      />

                      <div style={{ marginTop: '15px', padding: '8px', display: 'flex' }}>
                        <div style={{ fontSize: '18px', margin: '8px' }}>
                          <span style={{ fontWeight: 'bold' }}>Total 20% a cobrar:</span> S/. {proyecto.datos_totales.total_20}
                        </div>
                        <div style={{ fontSize: '18px', margin: '8px' }}>
                          <span style={{ fontWeight: 'bold' }}>Total 100% a cobrar:</span> {proyecto.datos_totales.total_100}
                        </div>
                      </div>
                    </div>
                  </div>
                )) : <p>No hay datos disponibles</p>
              }
            </SpinnerLoading>
          )
        }

      </div>
      <ModalLarge
        show={show}
        updateModalLarge={updateModalLarge}
        title={`Anotaciones ${desertado.nombre}`}
      >
        <Agenda />
      </ModalLarge>

      {/* Modal para la visibilidad de columnas */}
      <Modal show={showModal} onHide={handleCloseModal} className="modal-columnas">
        <Modal.Header closeButton>
          <Modal.Title>Configuración de Columnas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {colums(btnExaminar).map((column) => (
              <Form.Check
                key={column.key}
                type="switch"
                id={column.key}
                label={column.text}
                checked={!columnVisibility[column.key]} // Si no está oculta, el switch está activado
                onChange={() => toggleColumn(column.key)} // Alternar visibilidad de la columna
                className="mb-2"
              />
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    </Layout>
  );
};

export default compose(widthModal)(ReporteDesertados);

const colums = (btnExaminar) => [
  {
    text: "ID",
    key: "id_matricula",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Discente",
    key: "nombre",
    align: "center",
    sortable: true,
    width: 500,
    className: "type_of_food td-discente"
  },
  {
    text: "DNI",
    key: "dni",
    align: "center",
    sortable: false,
    width: 500,
    className: "type_of_food"
  },
  {
    text: "Asesor",
    key: "asesor",
    align: "center",
    sortable: true,
    width: 500,
    className: "type_of_food"
  },
  {
    text: "Contrato firmado",
    key: "contrato",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "N° de celular",
    key: "celular",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Correo",
    key: "email",
    align: "center",
    sortable: true,
    className: "type_of_food"
  },
  {
    text: "Solicitud de desersión dentro del plazo (7 días calendarios)",
    key: "solicitud_plazo",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-solicitud"
  },
  {
    text: "Aplica devolución",
    key: "devolucion",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "Sesiones asistidas",
    key: "cantidad_asistencia",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "Inversión (Precio Total)",
    key: "inversion",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "Monto abonado",
    key: "monto_abonado",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "N° Sesiones",
    key: "cantidad_sesiones",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "Inversión por sesión",
    key: "inversion_por_sesion",
    align: "center",
    sortable: true,
    className: "type_of_food text-center"
  },
  {
    text: "20% (% DSCTO OBLIGATORIO SIN ASISTENCIA)",
    key: "20%",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-cobrar-2"
  },
  {
    text: "100% (% COBRO OBLIGATORIO CON ASISTENCIA)",
    key: "cobro_obligatorio_100%",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-cobrar-2"
  },
  {
    text: "Monto a cobrar 20%",
    key: "monto_a_cobrar_20%",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-cobrar"
  },
  {
    text: "Monto a cobrar 100%",
    key: "monto_a_cobrar_100%",
    align: "center",
    sortable: true,
    className: "type_of_food text-center td-cobrar"
  },
  {
    key: "action",
    text: "Anotaciones",
    className: "action text-center",
    width: 100,
    align: "center",
    sortable: false,
    cell: record => btnExaminar(record)
  }
]
