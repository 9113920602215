import React, { useEffect } from 'react';
import Layout from '../../../../../app/Layout';
import Breadcrumb from '../../../General/Breadcrumb';
import HeaderContent from '../../../General/HeaderContent';
import { Tabs, Tab, Button, Card, Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import './AreaCiclos.scss';
import MaterialTableDemo from '../../../../components/plugins/MaterialTableDemo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import FormNuevoProyecto from '../../../../components/ProgramacionAnual/FormNuevoProyecto';
import SpinnerLoading from '../../../../components/Spinners/SpinnerLoading';
import ModalBasicwithRedux from '../../../../../containers/ModalBasicwithRedux';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { eliminarProyectoProgramacion, obtenerProgramacion, proyectoProgramacionActivo } from '../../../../../actions/programacionesActions';
import { updateStateModalBasic } from '../../../../../actions/modalActions';
import GraficoCiclos from '../../../../components/ProgramacionAnual/GraficoCliclos';
import Proveedores from '../../../../components/ProgramacionAnual/Proveedores';

const AreaCiclos = () => {

  const { idProgramacion } = useParams();
  const dispatch = useDispatch();
  const {
    programacionActiva: activo,
    loading,
    error
  } = useSelector(state => state.programaciones);
  const proyectosProgramacion = useSelector(state => state.programaciones.programacion.todo);
  const proyectos = proyectosProgramacion ? proyectosProgramacion[idProgramacion] : []

  const handleModal = () => {
    //Llamando al modal y eliminando al proyecto activo
    dispatch( updateStateModalBasic(<FormNuevoProyecto/>, 'Nuevo Producto') )
    dispatch(proyectoProgramacionActivo());
  }

  const handleEliminar = (idProgrmProyecto) => {
    dispatch(eliminarProyectoProgramacion(idProgramacion, idProgrmProyecto));
  }

  const handleEditar = (idProgrmProyecto) => {
    //LLamando al modal y añadiendo el proyecto activo
    dispatch( updateStateModalBasic(<FormNuevoProyecto/>, 'Editar Producto') )
    dispatch(proyectoProgramacionActivo(idProgramacion, idProgrmProyecto));
  }

  const mostrarAcciones = ({ idProgrmProyecto }) => <>
    <Button
      variant='danger'
      size='xs'
      onClick={() => handleEliminar(idProgrmProyecto)}
    >
      <FontAwesomeIcon icon={faTrash}/>
    </Button>
    <Button
      variant='info'
      size='xs'
      onClick={() => handleEditar(idProgrmProyecto)}
    >
      <FontAwesomeIcon icon={faEdit} />
    </Button>
  </>

  const mostrarEnlaceGlobal = (enlace = null) => {
    if (enlace) return <a href={enlace} rel="noopener noreferrer" target="_blank">{enlace}</a>
    return <span className='text-danger'>No hay enlace</span>
  }

  useEffect(() => {
    dispatch(obtenerProgramacion(idProgramacion));
  }, [dispatch, idProgramacion])

  return ( 
    <Layout>
      <div className="AreaCiclos">
        <div className="AreaCilos__head">
          <div className="AreaCiclos__title">
            <HeaderContent
              title='Productos Viables'
              subItem={activo && activo.programacionAnual}
            />
          </div>
          <div className="AreaCiclos__breadcrumb">
            <Breadcrumb
               link={[
                { url: "/inicio", nombre: "Inicio", icon: "fas fa-home" },
                { url: "/prototipado", nombre: "Prototipado" },
                { url: "", nombre: "Productos viables" }
              ]}
            />
          </div>
        </div>
        <div className="AreaCiclos__body">
        <SpinnerLoading loading={loading} error={error}>
          <Row className="enlaces-globales">
            <Col md={4}>
              <Card className='w-100'>
                <Card.Header>Enlaces globales de la programación</Card.Header>
                <Card.Body>
                  <p>URL Inversión: {mostrarEnlaceGlobal(activo && activo.prototipa)}</p>
                  <p>URL Medición: {mostrarEnlaceGlobal(activo && activo.mide)}</p>
                  <p>URL Formulación: {mostrarEnlaceGlobal(activo && activo.formula)}</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className='AreaCiclos__acciones text-right'>
            <Button onClick={handleModal} variant='primary' className='btn-icon'>
              <FontAwesomeIcon icon={faPlus} />
              Nuevo Producto
            </Button>
          </div>
          <ModalBasicwithRedux />
         
              <Tabs
                defaultActiveKey="tabla_ciclos"
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="tabla_ciclos" title="Producto">
                  <div className="tabla">
                    <MaterialTableDemo
                      colums={columns(mostrarAcciones)}
                      tabla={proyectos}
                    />
                  </div>
                </Tab>
                <Tab eventKey="proveedores" title="Logística">
                  <Proveedores />
                </Tab>
              { /* <Tab eventKey="grafico" title="Gráficos">
                  <GraficoCiclos />
                </Tab>*/}
              </Tabs>
           
        </SpinnerLoading>
        </div>
      </div>
    </Layout>
  );
}
 
export default AreaCiclos;

const columns = (mostrarAcciones) => [
  {
    text:'ID',
    key: 'idProgrmProyecto',
    sortable: true
  },
  {
    text:'Nombre',
    key: 'nombre'
  },
  {
    text: 'Fecha inicio',
    key: 'fechaInicio',
    sortable: true
  },
  {
    text: 'Fecha fin',
    key: 'fechaFin',
    sortable: true
  },
  {
    text: 'Ciclo',
    key: 'ciclo'
  },
  {
    text: 'Color',
    key: 'color',
    align: 'center',
    cell: recors => <div
      style={{
        background: recors.color,
        margin: "auto",
        width: "50px",
        height: "20px"
      }}></div>
  },
  {
    text: 'Acciones',
    className: 'text-center',
    align: 'center',
    width: 100,
    cell: record => mostrarAcciones(record)
  }
]