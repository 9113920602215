import React from 'react';
import AccionesTablaControl from '../../view/components/Tablas/ControlCapacitaciones/AccionesTablaControl';
import CurriculumDocente from '../../view/components/Tablas/ControlCapacitaciones/CurriculumDocente';
import ProcesosIntranet from '../../view/components/Tablas/ControlCapacitaciones/ProcesosIntranet';
import EstadoCalidad from '../../view/components/Tablas/ControlCapacitaciones/AjustesCalidad';
import { useParams } from 'react-router-dom';
import { Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faEdit, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import DetallesSesionModal from './DetallesSesionModal';
import AgregarLinkDriveForm from './AgregarLinkDriveForm';
import AgregarLinkDriveButton from './AgregarLinkDriveButton';
//Redux
import { useDispatch } from 'react-redux';
import { enviarConfirmacionCorreo, enviarInvitacionCorreo, guardarSesionCapacitacionActivo, obtenerDetallesSesion } from '../../actions/controlCapacitacionesActions';
import { updateStateModalBasic, updateStateModalLarge } from '../../actions/modalActions';
import EmailPreview from './EmailPreview';

const withTablaControl = WrappedComponent => props => {
  const { idCapacitacion } = useParams();
  const dispatch = useDispatch();

  const mostrarBadge = ({ proceso }) => {
    if (!proceso) return null;
    const totalIndicadores = proceso.length;
    let indicadoresCompletados = 0;
    [...proceso].forEach(element => {
      if (element.estado === 'Completo') {
        indicadoresCompletados++;
      }
    });
    const resultado = (indicadoresCompletados / totalIndicadores) * 100;
    return (
      <Badge
        variant={`${resultado < 50 ? 'danger' : resultado < 100 ? 'warning' : 'success'}`}
        className='text-white px-2'
      >
        {parseFloat(resultado).toFixed(2)}%
      </Badge>
    );
  };

  const handleAccioneButton = ({ id_sesion }) => {
    dispatch(guardarSesionCapacitacionActivo(id_sesion, idCapacitacion));
    dispatch(updateStateModalLarge(<AccionesTablaControl />, 'Acciones para los docentes'));
  };

  const handleViewCurriculum = ({ id_sesion }) => {
    dispatch(guardarSesionCapacitacionActivo(id_sesion, idCapacitacion));
    dispatch(updateStateModalLarge(<CurriculumDocente />, 'Curriculum Vitae'));
  };

  const handleViewProcesos = ({ id_sesion }) => {
    dispatch(guardarSesionCapacitacionActivo(id_sesion, idCapacitacion));
    dispatch(updateStateModalBasic(<ProcesosIntranet />, 'Progreso de la intranet'));
  };

  const handleViewEstado = ({ id_sesion }) => {
    dispatch(guardarSesionCapacitacionActivo(id_sesion, idCapacitacion));
    dispatch(updateStateModalBasic(<EstadoCalidad />, 'Acciones sobre la calificación'));
  };

  const handleVerDetallesSesion = (record) => {
    dispatch(obtenerDetallesSesion(record.id_sesion));
    // Aquí podrías abrir un modal o mostrar los detalles de alguna forma
    dispatch(updateStateModalLarge(<DetallesSesionModal />, 'Detalles de la Sesión'));
  };

  const handleCorreoConfirmacion = (record) => {
    dispatch(updateStateModalLarge(
      <EmailPreview
        datos={record}
        tipo={2}
      ></EmailPreview>, 'Correo de Confirmación'));
      };

  const handleCorreoInvitacion = (record) => {
    dispatch(updateStateModalLarge(
    <EmailPreview
      datos={record}
      tipo={1}
    ></EmailPreview>, 'Correo de invitación'));
  };
  
  

  const mostrarBotones = (record) => {
    return (
      <>
        <Button
          variant='info'
          title='Acciones para el Docente'
          size='xs'
          onClick={() => handleAccioneButton(record)}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          variant='warning'
          title='Progreso de la Intranet'
          size='xs'
          onClick={() => handleViewProcesos(record)}
        >
          <FontAwesomeIcon icon={faTasks} />
        </Button>
      </>
    );
  };

  const estadoCalidad = (record) => {
    return (
      <>
        <Button
          variant='info'
          title='Progreso de Intranet'
          size='xs'
          onClick={() => handleViewEstado(record)}
        >
          <FontAwesomeIcon icon={faTasks} />
        </Button>
      </>
    );
  };

  const anchorSesiones = (record) => {
    return (
      <p
        type='button'
        className='btn btn-sm td__curriculum__btn--color'
        onClick={() => handleViewCurriculum(record)}
      >
        Ver curriculum
      </p>
    );
  };

  const colums = [
    {
      text: "Semaforo",
      key: "estado_semaforo",
      align: "center",
      sortable: false,
      className: "estado_semaforo text-center",
      width: 20,
      cell: (record) => {
        return (
          <div
            style={{
              color: 'white', background: `${record.estado_semaforo === 'AMBAR' ? '#F39C12' :
                  record.estado_semaforo === 'VERDE' ? '#18BB60' : '#E12E1C'
                }`
            }}
            className='estado_semaforo'
          >
            {record.estado_semaforo}
          </div>
        );
      }
    },
    {
      text: "Item",
      key: "index",
      align: "center",
      sortable: true,
      className: "text-center",
      width: 20,
      cell: (_, index) => {
        return (<>{index + 1}</>);
      }
    },
    {
      text: "ID",
      key: "id_sesion",
      align: "center",
      sortable: true,
      className: "text-center",
      width: 20,
    },
    {
      text: "Fecha",
      key: "fecha_sesion",
      align: "center",
      className: "tabla__td tabla__td__nombre-sesion",
      sortable: true,
      cell: (record) => {
        return <span style={{ width: '80px', display: 'block' }}>{moment(record.fecha_sesion).format('DD-MMM-YYYY')}</span>;
      }
    },
    {
      text: "Detalles Sesión",
      key: "detalles_sesion",
      align: "center",
      className: "text-center",
      sortable: false,
      cell: (record) => {
        return (
          <Button
            variant="info"
            size="xs"
            onClick={() => handleVerDetallesSesion(record)}
          >
            Ver Detalles
          </Button>
        );
      }
    },
    {
      text: "Agregar Link Drive",
      key: "agregar_link_drive",
      align: "center",
      className: "text-center",
      sortable: false,
      cell: (record) => {
        return (
          <AgregarLinkDriveButton
            idSesion={record.id_sesion}
            idDocente={record.docente_confirmado ? record.docente_confirmado[0]?.id_filtro_docente : null}
          />
        );
      }
    },
  
    {
      text: "Horas de ponencia",
      key: "horas_sesion",
      align: "center",
      className: "text-center tabla__td__fecha",
      sortable: true,
    },
    {
      text: "Módulo",
      key: "nombre_modulo",
      align: "center",
      className: "tabla__td tabla__td__modulo",
      sortable: true,
    },
    {
      text: "Sesión",
      key: "nombre_sesion",
      align: "center",
      className: "tabla__td tabla__td__temas",
      sortable: true,
    },
    {
      text: "Docentes tentativos/Jurado/Clausura",
      key: "docentes_tentativos",
      align: "center",
      className: "tabla__td--tentativo",
      width: 400,
      cell: record => {
        return (
          <ul>
            {(record.docentes_tentativos || []).map((docente) => (
              <li key={docente.id_filtro_docente}>
                {`${docente.nombre_usuario} ${docente.apellido_usuario}`}
              </li>
            ))}
          </ul>
        );
      }
    },
    {
      text: "Correo invitacion",
      key: "correo_invitacion",
      align: "center",
      className: "correo_invitacion",
      width: 400,
      cell: record => {
        return (
          <button onClick={() => handleCorreoInvitacion(record)} style={{
            display: "flex",
            margin: "auto auto",
            padding: "8px 16px",
            backgroundColor: "#007bff", // Color azul para resaltar
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: "bold",
            transition: "background-color 0.3s ease"
          }}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0056b3"} // Cambio de color al pasar el mouse
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#007bff"} // Color original
          >
        <FontAwesomeIcon icon={faEnvelope} />
        </button>
        );
      }
    },
    {
      text: "Docente confirmado",
      key: "docente_confirmado",
      align: "center",
      className: "tabla__td--confirmado",
      width: 400,
      cell: record => {
        return (
          <ul>
            {(record.docente_confirmado || []).map((docente) => (
              <li key={docente.id_filtro_docente}>
                {`${docente.nombre_usuario} ${docente.apellido_usuario}`}
              </li>
            ))}
          </ul>
        );
      }
    },
    {
      text: "Correo confirmacion",
      key: "correo_confirmacion",
      align: "center",
      className: "correo_confirmacion",
      width: 400,
      cell: record => {
        return (
          <button onClick={() => handleCorreoConfirmacion(record)} style={{
            display: "flex",
            margin: "auto auto",
            padding: "8px 16px",
            backgroundColor: "#007bff", // Color azul para resaltar
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: "bold",
            transition: "background-color 0.3s ease"
          }}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#0056b3"} // Cambio de color al pasar el mouse
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#007bff"} // Color original
          >
        <FontAwesomeIcon icon={faEnvelope} />
        </button>
        );
      }
    },
    {
      text: "Curriculum Vitae",
      key: "cv",
      align: "center",
      className: "td__curriculum",
      cell: record => {
        return (
          <>{anchorSesiones(record)}</>
        );
      }
    },
    {
      text: "Cópia del correo de confirmación",
      key: "archivo_confirmacion",
      align: "center",
      className: "tabla__td",
      width: 500,
      cell: record => {
        return (
          <>
            {(record.archivo_confirmacion && record.archivo_confirmacion.length > 0) ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${record.archivo_confirmacion[0].ruta_archivo}`}
                className='img img-fluid' alt="..."
              />
            ) : (
              'No hay imagen'
            )}
          </>
        );
      }
    },
    {
      text: "Calificación de Discentes",
      key: "calificacion",
      align: "center",
      className: "text-center",
      TrOnlyClassName: "tabla__th",
      sortable: true,
      cell: record => {
        return (
          <>
            {isNaN(record.calificacion) ? '0' : `${record.calificacion}%`}
          </>
        );
      }
    },
    {
      key: "estado",
      text: "Acciones sobre la calificación",
      className: "td__estado text-center",
      align: "center",
      sortable: false,
      cell: record => {
        return (
          <>
            {estadoCalidad(record)}
          </>
        );
      }
    },
    {
      text: "Evidencia de acciones",
      key: "archivo_evidencia",
      align: "center",
      className: "evidencia__td",
      width: 500,
      cell: record => {
        return (
          <>
            {(record.archivo_evidencia && record.archivo_evidencia.length > 0) ? (
              <img
                src={`${process.env.REACT_APP_API_URL}/${record.archivo_evidencia[0].ruta_archivo}`}
                className='img img-fluid' alt='...'
              />
            ) : (
              'No hay imagen'
            )}
          </>
        );
      }
    },
    {
      text: "Actualización de intranet",
      key: 'infoActualizacion',
      align: "center",
      className: "text-center",
      sortable: false,
      cell: record => {
        return (
          <>
            {mostrarBadge(record)}
          </>
        );
      }
    },
    {
      key: "action",
      text: "Acciones",
      className: "td__action text-center",
      align: "center",
      sortable: false,
      cell: record => {
        return (
          <>
            {mostrarBotones(record)}
          </>
        );
      }
    }
  ];

  return (
    <WrappedComponent
      {...props}
      colums={colums}
    />
  );
};

export default withTablaControl;
