import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { emitirPDFs, emitirPDFTerceros, verificarAptos } from "../../../actions/basesCertActions";
import useSelectProyecto from "../../../hooks/useSelectProyectos";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import { avisoError } from "../../components/AvisosMensajes/MensajesSwalf";

const FormularioEmision = () => {
	const dispatch = useDispatch();
	const [proyecto, SelectProyecto] = useSelectProyecto(null);
	const { aptos, loading } = useSelector((state) => state.bases);
	const { usuario } = useSelector((state) => state.usuario);
	const traerDatos = () => {
		if (proyecto) {
			const datosFiltro = {
				proyecto: proyecto ? proyecto.id_proyecto : null,
			};

			console.log(datosFiltro)
			dispatch(verificarAptos(datosFiltro));
		} else {
			avisoError("Debe seleccionar un producto...");
		}
	};

	const emitirDocumentos = () => {
		if (aptos.length) {
			dispatch(emitirPDFs(aptos, usuario));
		}
	};
	
	const emitirDocumentosTerceros = () => {
		if (aptos.length) {
			dispatch(emitirPDFTerceros(aptos, usuario));
		}
	}
	return (
		<div className="box">
			<div className="box-header with-border">
				<h5 style={{ color: "#434343" }}>
					<FontAwesomeIcon icon={faSearch} /> Acciones de emisión:
				</h5>
			</div>
			<div className="row p-2">
				<div className="col-9 mb-2">
					<label htmlFor="">Producto:</label>
					<SelectProyecto />
				</div>
				<div className="col-12 pt-4">
					<button className="btn btn-primary" onClick={traerDatos}>
						Consultar
					</button>
					{aptos.length && proyecto ? (
						<>
							{" "}
							<button
								className="btn btn-primary"
								onClick={emitirDocumentos}
							>
								Emitir
							</button>{" "}
							{/* <button
								className="btn btn-primary"
								onClick={emitirDocumentosTerceros}
							>
								Emitir a terceros
							</button> */}
							<a
								className="btn btn-primary"
								href={`https://apisistema.gestoraperu.pe/admin/excel/reporte/aptos/${proyecto.id_proyecto}`}
							>
								Descargar Nómina
							</a>{" "}
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default FormularioEmision;
