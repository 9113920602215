import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { updateStateModalBasic } from '../../../../actions/modalActions';
import { nuevoProyecto, editarProyectoProgramacion } from '../../../../actions/programacionesActions';
import { avisoError } from '../../AvisosMensajes/MensajesSwalf';
import FormNuevoObjetivo from './FormNuevoObjetivo';
import ModalBasicwithRedux from '../../../../containers/ModalBasicwithRedux';
import { set } from 'date-fns';

const FormNuevoProyecto = () => {
  const { idProgramacion } = useParams();
  const initialState = {
    id_proyecto: null,
    nombre: '',
    fechaInicio: '',
    fechaFin: '',
    prototipa: '',
    formula: '',
    color: '',
    idProgrmAnual: idProgramacion,
    idCiclo: null,
    publicoObjetivo: '',
    objetivos: [],
    inhouse: false
  }
  const { errorProgramacion } = useSelector(state => state.programaciones);
  const { proyectoActivo } = useSelector(state => state.programaciones.programacion);
  const [datosProyecto, setDatosProyecto] = useState(initialState)
  //const [datosProyecto, setDatosProyecto, resetValues] = useForm(proyectoActivo || initialState);
  const [objetivoEdit, setObjetivoEdit] = useState(null);
  const [showObjetivoModal, setShowObjetivoModal] = useState(false);

  const dispatch = useDispatch();
  const { id_proyecto, nombre, fechaInicio, fechaFin, prototipa, formula, color, idCiclo, publicoObjetivo, objetivos, inhouse } = datosProyecto;

  const handleSubmit = async e => {
    e.preventDefault();
    if (nombre === '' || fechaInicio === '' || fechaFin === '' || idCiclo === null || publicoObjetivo === null || objetivos.length === 0) {
      avisoError('Faltan llenar campos obligatorios');
      return;
    }
    // Valida si es editar o insertar
    if (proyectoActivo) {
      await dispatch(editarProyectoProgramacion(datosProyecto));
    } else {
      await dispatch(nuevoProyecto(datosProyecto));
    }
    if (errorProgramacion) setDatosProyecto(initialState);
  }

  const handleChange = e => {
    setDatosProyecto({
      ...datosProyecto,
      [e.target.name]: e.target.value
    })
  }

  const handleClose = () => {
    dispatch(updateStateModalBasic());
  }

  const handleModal = () => {
    if (showObjetivoModal) {
      setShowObjetivoModal(false);
      setObjetivoEdit(null);
    } else {
      setShowObjetivoModal(true);
    }
  }

  const handleEditarObjetivo = (index) => {
    setObjetivoEdit({ ...objetivos[index], index });
    handleModal();
  }

  const handleEliminarObjetivo = (index) => {
    const nuevosObjetivos = objetivos.filter((objetivo, i) => i !== index);
    setDatosProyecto({
      ...datosProyecto,
      objetivos: nuevosObjetivos
    });
  }
  useEffect(() => {
    if (proyectoActivo) {
      setDatosProyecto(prevDatosProyecto => ({
        ...prevDatosProyecto,
        id_proyecto: proyectoActivo.id_proyecto,
        idProgrmProyecto: proyectoActivo.idProgrmProyecto,
        nombre: proyectoActivo.nombre,
        fechaInicio: proyectoActivo.fechaInicio,
        fechaFin: proyectoActivo.fechaFin,
        color: proyectoActivo.color,
        idCiclo: proyectoActivo.idCiclo,
        objetivos: proyectoActivo.objetivos,
        publicoObjetivo: proyectoActivo.publico_objetivo,
        inhouse: proyectoActivo.inhouse === '1'
      }));
    }
  }, [proyectoActivo]);

  return (
    <div className="FormNuevoProyecto">
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group>
            <Form.Label className='label-required'>Nombre de producto</Form.Label>
            <Form.Control type='text' name='nombre' value={nombre} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label className='label-required'>Fecha de inicio</Form.Label>
            <Form.Control type='date' name='fechaInicio' value={fechaInicio} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label className='label-required'>Fecha de culminación</Form.Label>
            <Form.Control type='date' name='fechaFin' value={fechaFin} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Color de proyecto</Form.Label>
            <Form.Control type='color' name='color' value={color} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Form.Label className='label-required'>Seleccionar ciclo</Form.Label>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Check
                  type='radio'
                  label='Ciclo I'
                  name='idCiclo'
                  id='formRadioCiclo1'
                  checked={parseInt(idCiclo) === 1 ? true : false}
                  value={1}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Check
                  type='radio'
                  label='Ciclo II'
                  name='idCiclo'
                  id="formRadioCiclo2"
                  checked={parseInt(idCiclo) === 2 ? true : false}
                  value={2}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Label className='label-required'>IN HOUSE</Form.Label>
            <Form.Group as={Col}>
              <Form.Check
                type='checkbox'
                label='Inhouse'
                name='inhouse'
                id='formRadioinhouse'
                checked={inhouse}
                onChange={() => setDatosProyecto({ ...datosProyecto, inhouse: !inhouse })}
              />
            </Form.Group>

          </Form.Group>
          <Form.Group>
            <Form.Label className='label-required'>Público Objetivo</Form.Label>
            <Form.Control type='text' name='publicoObjetivo' value={publicoObjetivo} onChange={handleChange} />
          </Form.Group>
          <Form.Group>
            <Button variant='primary' onClick={handleModal}>Agregar Objetivo</Button>
            {objetivos.length > 0 &&
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Titulo</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {objetivos.map((objetivo, index) => (
                    <tr key={index}>
                      <td className='text-center'>{index + 1}</td>
                      <td className='text-center'>{objetivo.titulo_objetivo}</td>
                      <td className='text-center'>
                        <Button variant='warning' onClick={() => handleEditarObjetivo(index)}><FontAwesomeIcon icon={faEdit} /></Button>
                        <Button variant='danger' onClick={() => handleEliminarObjetivo(index)}><FontAwesomeIcon icon={faTrash} /></Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className='text-right'>
          <Button
            variant='dark'
            type='button'
            className='btn-icon'
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={faTrash} />
            Cancelar
          </Button>
          <Button
            variant='info'
            type='submit'
            className='btn-icon'
          >
            {
              proyectoActivo ?
                'Guardar cambios'
                : <>
                  <FontAwesomeIcon icon={faPlus} />
                  Crear nuevo
                </>
            }
          </Button>
        </Modal.Footer>
      </Form>

      {/* Modal para agregar objetivos */}
      <Modal
        show={showObjetivoModal}
        onHide={handleModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton style={{ backgroundColor: '#262f48', color: 'white' }}>
          <Modal.Title>{!objetivoEdit ? 'Agregar Nuevo Objetivo' : 'Editar Objetivo'}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#262f48', color: 'white' }}>
          <FormNuevoObjetivo
            cerrarModal={handleModal}
            setDatosProyecto={setDatosProyecto}
            objetivos={objetivos}
            objetivoEdit={objetivoEdit}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FormNuevoProyecto;